import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"
import Section50Background from "components/heroes/Section50Background"



const AboutUs = () => {

  // using static header image, load here
  const data = useStaticQuery(
    graphql`
    query {
      heroImage: file(relativePath: { eq: "backgrounds/man-grid.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
      quoteImage: file(relativePath: { eq: "general/pv-wind-sunrise-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  `
  )

  const factsTable = [
    { th: "Trading Market", td1: "US OTCQB" },
    { th: "Ticker Symbol", td1: "PGTK" },
    { th: "2-year Orderbook", td1: "USD $200m+" },
    { th: "FY2020 Revenue", td1: "USD $130m" },
    { th: "FY2020 Net Income", td1: "USD $10.3m" },
    { th: "Offices", td1: "USA, UK, China, Canada, Sydney" },
  ]


  return (
    <Layout>
      <Seo title="About Us" />

      {/* Title banner */}
      <ImgBackground image={data.heroImage}>
        <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex z-2">
          <TitleBox
            title="Why choose Pacific Green?"
            caption="About Us"
          >
            <p className="text-2xl text-white font-normal">Pacific Green delivers industry-leading expertise gained from the deployment major solar, storage and electric vehicle charging projects around the world. Our team have been involved in some of the UK’s largest solar car parks and EV forecourts and delivering more than 1GWh of energy storage capacity.</p>
          </TitleBox>
        </div>
      </ImgBackground>

      {/* content and map */}
      <section className="">
        <div className="container lg:flex">
          <div className="lg:w-1/2">
            <div className="py-8 md:py-16 lg:py-32 justify-items-end">
              <div className="mb-12 prose max-w-prose">
                <p className="text-xl">Unlock the solar and EV potential for your business, its customers and your employees by building a relationship with a company you can trust to deliver successful, hassle- free results.</p>
                <p>As a global cleantech and renewables supplier, Pacific Green has unrivalled financial clout when it comes to supplying top tier PV systems at competitive prices.</p>
                <p>Our partnerships with the World's leading manufacturers and vertically integrated supply chain ensures can reap the benefits when it comes to value and performance.</p>
              </div>
            </div>
          </div>

          <div className="pb-16 md:py-32 md:pl-16">
            <StaticImage src="../images/general/offices-map.png" alt="PGT global offices map" width="1100" />
          </div>

        </div>
      </section>

      {/* 50/50 image pullquote divider */}
      <section className="bg-primary-50">
        <div className="container lg:flex">
          <div className="lg:w-1/2">
            <div className="p-8 md:p-16 lg:p-32 md:pl-0 lg:pl-0 max-w-4xl justify-items-end">
              <div className="mb-12 lg:w-3/4 prose">
                <h2 className="text-2xl  mb-4">A Global Presence</h2>
                <p>Pacific Green PV Solar is a wholly owned subsidiary of Pacific Green Group with offices in five countries and a large manufacturing base covering multiple greentech sectors. We are already delivering success for our clients around the world. </p>
                <p>With a wealth of experience and a vertically integrated organisation, Pacific Green has the strength and backing to ensure your project also joins in that success.</p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="lg:float-left lg:w-1/2-screen lg:h-full lg:flex">
              <Section50Background image={data.quoteImage}>
                <div className="bg-white bg-opacity-90 p-16">

                  <h2 className="text-2xl mb-2">Quick facts</h2>
                  {factsTable.map((item, key) => (
                    <div key={key} className="flex py-2 border-b border-primary-200 border-dashed">
                      <div className="w-1/2 font-bold mr-4">{item.th}</div>
                      <div className="w-1/2">{item.td1}</div>
                    </div>
                  ))}

                </div>
              </Section50Background></div>
          </div>
        </div>
      </section>



    </Layout>
  )
}

export default AboutUs
