import * as React from 'react'

import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

const Section50Background = ({children, image}) => {
    
    const imageFile = getImage(image)
    const bgImage = convertToBgImage(imageFile)
    
    return(
        <BackgroundImage Tag="div" className="z-0 w-full h-full" {...bgImage} preserveStackingContext>
            {children}
        </BackgroundImage>
    )
}

export default Section50Background